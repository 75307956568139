<template>
  <main>
    <package-header title="Title Searches" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <router-link to="/pages/title-searches/purchase" class="nav-link active"> Purchase </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pages/title-searches/manage" class="nav-link"> Manage Purchases </router-link>
            </li>
          </ul>

          <!-- Main package content -->
          <div class="row">
            <!-- Data entry form -->
            <div class="col-md-8">
              <p class="lead">Select items to perform a title search. These will be conducted and processed for payment immediately.</p>

              <h3 class="mt-4">Select the PID(s) for your order ($12.82 per title)</h3>
              <table class="table table-stacked table-select-row mb-4">
                <thead class="bg-light">
                  <tr>
                    <th scope="row">
                      <div class="custom-control custom-checkbox">
                        <input id="th" type="checkbox" class="custom-control-input" />
                        <label class="custom-control-label" for="th">&nbsp; </label>
                      </div>
                    </th>
                    <th scope="col">PID</th>
                    <th scope="col">Short Legal Description</th>
                    <th class="w-25" scope="col">Title</th>
                    <th class="w-25" scope="col">Registered Owner</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems" :key="index">
                    <td scope="row">
                      <div class="custom-control custom-checkbox" v-if="!item.multiple">
                        <input :id="index" type="checkbox" class="custom-control-input" />
                        <label class="custom-control-label" :for="index">&nbsp; </label>
                      </div>
                    </td>
                    <td data-header="PID">
                      <span class="td-content text-nowrap">{{ item.b }}</span>
                    </td>
                    <td data-header="Short Legal Description">
                      <span class="td-content">{{ item.c }}</span>
                    </td>
                    <td data-header="Registered Owner" :class="item.multiple && 'p-0'" :colspan="item.multiple ? 2 : 1">
                      <span v-if="!item.multiple" class="td-content text-nowrap">{{ item.d }}</span>

                      <table class="table mb-0" v-if="item.multiple && multipleItemsSelected">
                        <tr>
                          <td class="w-50 border-top-0">
                            <svg class="icon text-info mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
                            BR211596 VA
                          </td>
                          <td class="w-50 border-top-0">SMART, BECKY</td>
                        </tr>
                        <tr>
                          <td>
                            <svg class="icon text-info mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
                            BM216728 VA
                          </td>
                          <td>DOE, JANE</td>
                        </tr>
                      </table>
                      <div class="p-1" v-if="item.multiple">
                        <strong>Multiple titles found. <a data-toggle="modal" data-target="#duplicateFiling" href="#">Make selection</a></strong>
                      </div>
                    </td>
                    <td data-header="Registered Owner" v-if="!item.multiple">
                      <span class="td-content">{{ item.e }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-4">
              <div class="card bg-medium border-0 sticky-top">
                <div class="card-body">
                  <h3 class="card-title">Order Summary</h3>

                  <dl class="mt-2">
                    <dt>File Reference</dt>
                    <dd>ABC123</dd>
                  </dl>

                  <div id="accordion">
                    <button class="btn collapse-btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                      <strong>3 Items Requested</strong>
                    </button>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="collapse-body px-0 pt-2">
                        <div class="d-flex justify-content-between">
                          <ul class="list-unstyled mb-0">
                            <li><strong>PID: 015-671-569 </strong></li>
                            <li>Title: KT119399 NE</li>
                            <li>Owner: Smith, Parker</li>
                          </ul>
                          <span>$12.82</span>
                        </div>
                        <hr />
                        <div class="d-flex justify-content-between">
                          <ul class="list-unstyled mb-0">
                            <li><strong>PID: 015-671-570 </strong></li>
                            <li>Title: KT119372 NE</li>
                            <li>Owner: Durham, Higgins</li>
                          </ul>
                          <span>$12.82</span>
                        </div>
                        <hr />
                        <div class="d-flex justify-content-between">
                          <ul class="list-unstyled mb-0">
                            <li><strong>PID: 015-671-570 </strong></li>
                            <li>Title: KT119372 NE</li>
                            <li>Owner: Lee, Jason</li>
                          </ul>
                          <span>$12.82</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="d-flex justify-content-between">
                    <span>Service Charge:</span>
                    <span>$2.00</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Subtotal:</span>
                    <span>$38.46</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>GST:</span>
                    <span>$1.92</span>
                  </div>

                  <hr />

                  <div class="d-flex justify-content-between">
                    <strong>Total</strong>
                    <strong>$40.38</strong>
                  </div>

                  <dl class="mt-2 small">
                    <dt>GST Registration Number</dt>
                    <dd>81630 4414 RT0001</dd>
                  </dl>

                  <button class="btn btn-primary mt-2">Place Order (3)</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Diplicate filing number modal -->
      <div class="modal fade" id="duplicateFiling" tabindex="-1" role="dialog" aria-labelledby="duplicateFilingLabel" aria-hidden="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="duplicateFilingLabel">Add New Charge, Lien, or Interest</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                </span>
              </button>
            </div>
            <div class="modal-body">
              <p>Titles available for 015-671-572 <strong>($12.82 per title)</strong>:</p>

              <table class="table table-stacked table-select-row">
                <thead class="bg-light">
                  <tr>
                    <th scope="row">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="all" />
                        <label class="custom-control-label" for="all">&nbsp;</label>
                      </div>
                    </th>
                    <th scope="col">Title</th>
                    <th scope="col">Registered Owner</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in modalTableItems" :key="index">
                    <td style="width: 1rem" scope="row">
                      <div class="custom-control custom-checkbox">
                        <input :id="index" type="checkbox" class="custom-control-input" />
                        <label class="custom-control-label" :for="index">&nbsp; </label>
                      </div>
                    </td>
                    <td scope="col" data-header="Title">
                      <span class="td-content">{{ item.b }}</span>
                    </td>
                    <td scope="col" data-header="Registered Owner">
                      <span class="td-content">{{ item.c }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="multipleItemsSelected = false" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary" @click="multipleItemsSelected = true" data-dismiss="modal">Add</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>
var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected;
    });
  },
};

import PackageHeader from "../../../components/PackageHeader.vue";
import PackageNavigation from "../../../components/PackageNavigation.vue";
import HelpPanel from "../../../components/HelpPanel.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel,
  },

  data() {
    return {
      multipleItemsSelected: false,
      postSearch: false,
      tableItems: [
        {
          a: "1",
          b: "015-671-569",
          c: "S/1592///A//8",
          d: "KT119399 NE",
          e: "SMART, BECKY",
          multiple: false,
        },
        {
          a: "2",
          b: "015-671-570",
          c: "S/1592///A//9",
          d: "KT119372 NE",
          e: "ARMSTRONG, MARY JOANNE",
          multiple: false,
        },
        {
          a: "3",
          b: "015-671-571",
          c: "S/1592///A//10",
          d: "KT119948 NE",
          e: "DOE, JANE",
          multiple: true,
        },
        {
          a: "4",
          b: "015-671-572",
          c: "S/1592///A//11",
          d: "KT119321 NE",
          e: "PARK,SARAH",
          multiple: false,
        },
      ],

      modalTableItems: [
        {
          a: "1",
          b: "BR211596 VA",
          c: "SMART, BECKY",
        },
        {
          a: "2",
          b: "BM216728 VA",
          c: "ARMSTRONG, MARY JOANNE",
        },
        {
          a: "3",
          b: "KT119348 VA",
          c: "DOE, JANE",
        },
        {
          a: "4",
          b: "KT119321 NE",
          c: "PARK, SARAH",
        },
        {
          a: "4",
          b: "KT119321 NE",
          c: "TRUHAM, PARKER",
        },
      ],
    };
  },
};
</script>
